import Ant from '../../../assets/images/Ant.png';
import Divider from '../../../assets/images/Divider.svg';
import CoinIcon from '../../../assets/images/CoinIcon.png';
import ArrowRight from '../../../assets/images/ArrowRight.svg';
import WebApp from '@twa-dev/sdk';
import Done from '../../../assets/images/Done.svg';
import { formatNumber } from '../../../utils';
import RoundedIconLevels from '../../RoundedIconLevels/RoundedIconLevels';

export default function RefferalsItems({ data, onClickClaimReferral, id }) {
  const height = WebApp.viewportHeight;


  return (
    <div className="mt-3 h-[95%]">
      <label htmlFor="copyLink" className="text-base font-medium leading-6 mb-4 text-white">
        My referrals
      </label>
      <div className={`flex flex-col gap-y-2 ${height > 667 ? 'max-h-[80%]' : 'max-h-[60%]'}  overflow-y-auto`}>
        {data?.map(({ league, points_count, profit_by_referral, telegram_id }, index) => (
          <div
            key={index}
            className="rounded-[11px] bg-[#1B1B1B] flex items-center justify-between mt-2 px-4 py-2 gap-x-2"
            onClick={() => onClickClaimReferral(id, telegram_id)}>
            <p className="font-switzer text-base font-medium text-[#FFEB3B]">{formatNumber(index + 1)}</p>
            <div className="flex flex-col items-start">
              <p className="text-base text-white">{telegram_id}</p>
              <div className="flex gap-x-3 items-center">
                {/* <img src={Ant} alt="Ant" className="w-5 h-5" /> */}
                <RoundedIconLevels level_id={league?.league_id} />
                <p className="text-xs text-white font-switzer">{league?.title}</p>
                <img src={Divider} alt="Divider" />
                <img src={CoinIcon} alt="CoinIcon" className="w-5 h-5" />
                <p className="text-xs font-switzer text-[#979797] font-medium">{formatNumber(points_count)}</p>
              </div>
            </div>
            <p className="font-medium text-base text-[#FFEB3B] font-switzer">+{formatNumber(profit_by_referral)}</p>
            {profit_by_referral === 0 ? (
              <img src={ArrowRight} alt="ArrowRight" className="w-5 h-5" />
            ) : (
              <img src={Done} alt="Done" className="w-5 h-5" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

import { $authHost } from '..';


export default class BoostsHttp {

  static getBoosts(id, query_id, user, auth_date, hash) {
    return $authHost.get(`/boosts/${id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };

  static getDailyBoosts(id, task_id, query_id, user, auth_date, hash) {
    return $authHost.post(`/boosts/${id}/daily/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };

  static getBuyBoosts(id, task_id, query_id, user, auth_date, hash) {
    return $authHost.post(`/boosts/${id}/buy/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };

}
import { configureStore } from '@reduxjs/toolkit';
import errors from './errors/errors';
import user from './user/user';
import referrals from './referrals/referrals';


export const store = configureStore({
  reducer: {
    error: errors,
    user: user,
    referrals: referrals,
  },
});

import { useMemo } from 'react';

export default function TaskTub({ activeTab, onClickTab }) {
  const buttonsList = useMemo(
    () => [
      {
        name: 'Special',
        title: 'Special',
      },
      {
        name: 'Leagues',
        title: 'Leagues',
      },
      {
        name: 'RefTasks',
        title: 'Ref Tasks',
      },
    ],
    [],
  );
  

  return (
    <div className="px-4 pt-4">
      <div className="p-2 bg-[#1B1B1B] rounded-[22px] flex items-center justify-between gap-x-[7px]">
        {buttonsList?.map(({ name, title }, index) => (
          <button
            key={index}
            className={`w-full ${
              activeTab === name && 'bg-[#2CA9F2]'
            }  px-[16px] py-3 rounded-[11px] text-white font-switzer font-medium`}
            onClick={() => onClickTab(name)}>
            {title}
          </button>
        ))}
      </div>
    </div>
  );
}

import WebApp from '@twa-dev/sdk';
import CoinIcon from '../../../../assets/images/CoinIcon.png';
import RoundedIconLevels from '../../../../components/RoundedIconLevels/RoundedIconLevels';
import TaskHttp from '../../../../http/TaskHttp/TaskHttp';
import { formatNumber } from '../../../../utils';
export default function ShowLeagues({ data, user_points, telegram_id, setUpd }) {
  const onClaimLeague = (id, task_id) => {
    TaskHttp.claimLeague(
      id,
      task_id,
      WebApp?.initDataUnsafe?.query_id,
      JSON.stringify(WebApp?.initDataUnsafe?.user),
      WebApp?.initDataUnsafe?.auth_date,
      WebApp?.initDataUnsafe?.hash,
    ).then(e => setUpd(prev => !prev));
  };

  return (
    <div className="px-4 flex flex-col gap-y-2 mt-2 max-h-[46%] overflow-y-auto">
      {data?.map(({ title, league_id, bonus_points, status, milestone_points }) => (
        <div className="flex flex-col  bg-[#1B1B1B] rounded-[11px] gap-y-2 px-5 py-2" key={league_id + bonus_points}>
          <div className="flex items-center justify-between ">
            <div className="flex items-center gap-x-4 w-full">
              <RoundedIconLevels level_id={league_id} />
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col items-start">
                  <p className="text-white text-base font-normal font-switzer">{title}</p>
                  <div className="flex items-center gap-x-2">
                    <img src={CoinIcon} alt="CoinIcon" className="w-5 h-5" />
                    <p className="text-[#FFEB3B] text-base font-medium font-switzer">{formatNumber(bonus_points)}</p>
                  </div>
                </div>
                <button
                  className={`${
                    status === 'not_reached' || status === 'passed'
                      ? `w-[64px] h-[28px] rounded-[22px] flex items-center justify-center text-sm font-normal text-white bg-[#979797] cursor-not-allowed	`
                      : `w-[64px] bg-gradient-to-b from-[#FFB000] to-[#FF4000] h-[28px] rounded-[22px] flex items-center justify-center text-sm font-normal text-white`
                  }
                   $`}
                  disabled={status === 'not_reached' || status === 'passed'}
                  onClick={() => onClaimLeague(telegram_id, league_id)}>
                  {status === 'passed' ? 'Claimed' : 'Claim'}
                </button>
              </div>
            </div>
          </div>
          <div className={`w-full rounded-[44px] h-[20px] bg-[#979797] relative flex text-center overflow-hidden`}>
            <p className="absolute inset-[-2px] m-auto z-10 text-white">
              {`${
                Math.round((user_points / milestone_points) * 100) >= 100 || status === 'passed'
                  ? 100
                  : Math.round((user_points / milestone_points) * 100)
              } %`}
            </p>
            {Math.round((user_points / milestone_points) * 100) >= 100 || status === 'passed' ? (
              <div className="w-full h-[20px] absolute bg-gradient-to-b from-[#00AAE5] to-[#002EC9] rounded-[44px]"></div>
            ) : (
              <div
                className={'h-[20px] absolute bg-gradient-to-b from-[#00AAE5] to-[#002EC9] rounded-[44px]'}
                style={{ width: `${Math.round((user_points / milestone_points) * 100)}%` }}></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
//

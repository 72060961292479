import { NavLink } from 'react-router-dom';
import Referrals from '../../assets/images/Referrals.png';
import Task from '../../assets/images/Task.svg';
import TapIcon from '../../assets/images/TapIcon.svg';
import FireIcon from '../../assets/images/FireIcon.svg';

export default function Menu() {
  const MenuItems = [
    {
      id: 1,
      name: 'Referrals',
      icon: Referrals,
      isActive: false,
      href: '/referrals',
    },
    {
      id: 2,
      name: 'Task',
      icon: Task,
      isActive: false,
      href: '/task',
    },
    {
      id: 3,
      name: 'Tap',
      icon: TapIcon,
      isActive: true,
      href: '/',
    },
    {
      id: 4,
      name: 'Boost',
      icon: FireIcon,
      isActive: false,
      href: '/boosts',
    },
  ];

  return (
    // <div className="p-4 fixed bottom-0 w-full">
    <div className="px-4 mb-4">
      <div className="w-full h-[75px] rounded-[22px] bg-[#1B1B1B] p-2 flex gap-x-2 jusify-between">
        {MenuItems.map(({ icon, name, id, href }) => (
          <NavLink
            key={id}
            to={href}
            className={({ isActive }) =>
              isActive
                ? 'w-full h-[60px] flex items-center justify-center rounded-[11px] bg-[#0F0F0F]'
                : 'w-full h-[60px] flex items-center justify-center rounded-[11px]'
            }>
            <div className="flex flex-col items-center">
              <img src={icon} alt={name} />
              <p className="text-xs text-white">{name}</p>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

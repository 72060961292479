import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Tap from '../Tap/Tap';
import Layout from '../../components/Layout/Layout';
import Referrals from '../Referrals/Referrals';
import Task from '../Task/Task';
import Boosts from '../Boosts/Boosts';
import OurSocials from '../OurSocials/OurSocials';


const RootContainer = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Tap />} />
          <Route path={'/tap'} element={<Tap />} />
          <Route path={'/referrals'} element={<Referrals />} />
          <Route path={'/task'} element={<Task />} />
          <Route path={'/boosts'} element={<Boosts />} />
          <Route path={'/task/ourSocials/:id'} element={<OurSocials />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RootContainer;

import { $authHost } from "..";


export default class ReferralsHttp {

  static getReferralsList(id,query_id,user,auth_date,hash) {
    return $authHost.get(`/referrals/${id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static claimReferralsList(id,referral_id,query_id,user,auth_date,hash) {
    return $authHost.post(`/referrals/${id}/claim/${referral_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };


}
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import WebApp from '@twa-dev/sdk';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import { DialogTitle } from '@headlessui/react';
import { formatNumber } from '../../utils';
import RoundedIconLevels from '../RoundedIconLevels/RoundedIconLevels';

export function Swipers({ data, user_points, user }) {
  const height = WebApp.viewportHeight;

  const index = data.findIndex(item => item.league_id === user?.league_id);

  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        freeMode={false}
        loop={false}
        navigation={true}
        grabCursor={true}
        initialSlide={index}
        modules={[FreeMode, Navigation, Thumbs]}
        className="h-full"
        style={{
          '--swiper-navigation-color': '#979797',
          '--swiper-pagination-color': '#979797',
          '--swiper-navigation-size': '32px',
          '--swiper-pagination-size': '32px',
        }}>
        {data?.map(({ title, id, percent, milestone_points, icon, user_status }, index) => (
          <SwiperSlide
            key={id}
            className={`py-2 flex flex-col items-center justify-center w-full ${height > 667 && 'top-[10%]'} `}>
            <div className="w-[75%] flex flex-col items-center justify-center mx-auto">
              <DialogTitle as="h3" className="text-2xl font-semibold text-white leading-6 mb-4">
                {title}
              </DialogTitle>
              <div className="flex flex-col gap-y-3 items-center w-[70%]">
                <div className="flex items-center w-full justify-center h-[145px]">
                  <RoundedIconLevels level_id={id} big />
                </div>
                <p className="font-2xl text-white font-medium">Level {id}</p>
                {user_status === 'passed' || user_status === 'unclaimed' || user_points >= milestone_points ? (
                  <p className="font-base text-[#979797] font-medium">{`${formatNumber(
                    milestone_points,
                  )}/${formatNumber(milestone_points)}`}</p>
                ) : (
                  <p className="font-base text-[#979797] font-medium">{`${formatNumber(user_points)}/${formatNumber(
                    milestone_points,
                  )}`}</p>
                )}
              </div>
            </div>
            <div
              className={`w-full rounded-[44px] h-[20px] bg-[#979797] relative flex text-center mt-4 overflow-hidden`}>
              <p className="absolute inset-[-2px] m-auto z-50 text-white">{`${
                Math.round((user_points / milestone_points) * 100) >= 100 || user_status === 'passed' || user_status === 'unclaimed'
                  ? 100
                  : Math.round((user_points / milestone_points) * 100)
              } %`}</p>
              {Math.round((user_points / milestone_points) * 100) >= 100 || user_status === 'passed' || user_status === 'unclaimed' ? (
                <div className="w-full h-[20px] absolute bg-gradient-to-r from-[#F7F0AC] via-[#ACF7F0] to-[#F0ACF7] rounded-[44px]"></div>
              ) : (
                <div
                  className={
                    'h-[20px] absolute bg-gradient-to-r from-[#F7F0AC] via-[#ACF7F0] to-[#F0ACF7] rounded-[44px]'
                  }
                  style={{ width: `${Math.round((user_points / milestone_points) * 100)}%` }}></div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}



export default function NoReferrals() {


  return (
    <div className="mt-6">
      <label htmlFor="copyLink" className="text-base font-medium leading-6  mb-4 text-white">
      My referrals
      </label>
      <div className="h-32 rounded-[11px] bg-[#1B1B1B] flex items-center justify-center mt-2">
        <p className="text-[#979797] text-base font-normal font-switzer">You don’t have any referrals</p>
      </div>
    </div>
  );
}

import React from 'react';
import IconInvite from '../../assets/images/IconInvite.svg';
import WebApp from '@twa-dev/sdk';

export default function InviteButton({ title = 'Invite a friend' }) {
  const invit_link = process.env.REACT_APP_TG_BOT_URL;

  const url = `${invit_link}${WebApp?.initDataUnsafe?.user?.id}`;

  const handleShare = () => {
    WebApp.openTelegramLink(url);
  };

  return (
    <button
      className="w-full bg-gradient-to-b from-[#FFB000] to-[#FF4000] h-[56px] rounded-[11px] flex items-center justify-center"
      onClick={handleShare}>
      <div className="flex gap-x-2">
        <img src={IconInvite} alt="Invite Icon" className="" />
        <p className="text-white text-base font-medium font-switzer">{title}</p>
      </div>
    </button>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import UserHttp from '../../http/UserHttp/UserHttp';
import WebApp from '@twa-dev/sdk';

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    userData: '',
  },
  reducers: {
    USER_DATA(state, action) {
      state.userData = action.payload;
    },
  },
});

export const userDataSlice = id => async dispatch => {
  try {
    const data = await UserHttp.getUser(id,WebApp?.initDataUnsafe?.query_id, JSON.stringify(WebApp?.initDataUnsafe?.user), WebApp?.initDataUnsafe?.auth_date, WebApp?.initDataUnsafe?.hash);
    dispatch(USER_DATA(data?.data));
  } catch (error) {
    console.log(error, 'error');
    const created = await UserHttp.createUser({ telegram_id: id, referral_telegram_id: null },WebApp?.initDataUnsafe?.query_id, JSON.stringify(WebApp?.initDataUnsafe?.user), WebApp?.initDataUnsafe?.auth_date, WebApp?.initDataUnsafe?.hash);
    dispatch(USER_DATA(created?.data));
  }
};

export const { USER_DATA } = userSlice.actions;
export default userSlice.reducer;

import CopyToClipboard from 'react-copy-to-clipboard';
import IconCopy from '../../assets/images/IconCopy.svg';
import Done from '../../assets/images/Done.svg';
import { useCallback, useState } from 'react';

export default function InputCopyLink({value}) {
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <div className="relative mt-2 rounded-md shadow-sm">
      <label htmlFor="copyLink" className="text-base font-medium leading-6 text-white">
        My invite link
      </label>
      <input
        id="copyLink"
        name="copyLink"
        type="text"
        className="w-full py-3 pl-4 pr-8 bg-[#1B1B1B] rounded-[11px] text-[#979797] text-xs font-normal font-switzer mt-2"
        value={value}
        disabled
      />
      <div className="absolute inset-y-0 right-0 top-[32px] flex items-center pr-3 cursor-pointer">
        {!copied ? (
          <CopyToClipboard text={value} onCopy={onCopy}>
            <img src={IconCopy} alt="IconCopy" className="w-6 h-6" />
          </CopyToClipboard>
        ) : (
          <img src={Done} alt="Done" className="w-6 h-6" />
        )}
      </div>
    </div>
  );
}

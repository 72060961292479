import CoinIcon from '../../assets/images/CoinIcon.png';
import ArrowRight from '../../assets/images/ArrowRight.svg';
import Flash from '../../assets/images/icons/Flash.png';
import Tap from '../../assets/images/icons/Tap.png';
import Robot from '../../assets/images/icons/Robot.png';
import Batery from '../../assets/images/icons/Batery.png';
import FireIcon from '../../assets/images/FireIcon.svg';
import Lightning from '../../assets/images/lightning.png';
import LightningBigIcon from '../../assets/images/LightningBigIcon.png';
import AutoTapIcon from '../../assets/images/AutoTapIcon.png';
import AutoTapBigIcon from '../../assets/images/AutoTapBigIcon.png';
// import Tap from '../../assets/images/Tap.png';
import Energy from '../../assets/images/Energy.png';
import EnergyBigIcon from '../../assets/images/EnergyBigIcon.png';
import TapBigIcon from '../../assets/images/TapBigIcon.png';
import { BackButton } from '@twa-dev/sdk/react';
import ModalLevels from '../../components/Modal/ModalLevels';
import { useCallback, useEffect, useState } from 'react';
import ModalBoosts from '../../components/Modal/ModalBoosts';
import WebApp from '@twa-dev/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSlice } from '../../redux/user/user';
import BoostsHttp from '../../http/BoostsHttp/BoostsHttp';
import ModalBoostsDaily from '../../components/Modal/ModalBoostsDaily';
import { formatNumber, formatString } from '../../utils';
import RoundedIconLevels from '../../components/RoundedIconLevels/RoundedIconLevels';

export default function Boosts() {
  const dispatch = useDispatch();

  const id = WebApp?.initDataUnsafe?.user?.id;
  const user = useSelector(state => state?.user?.userData?.user);
  const league = useSelector(state => state?.user?.userData?.league);

  const data = [
    {
      title: 'multitap',
      icon: Tap,
      bigIcon: TapBigIcon,
    },
    {
      title: 'energy_limit',
      icon: Batery,
      bigIcon: EnergyBigIcon,
    },
    {
      title: 'recharging_speed',
      icon: Flash,
      bigIcon: LightningBigIcon,
    },
    {
      title: 'auto_tap',
      icon: Robot,
      bigIcon: AutoTapBigIcon,
    },
  ];

  const [openLevel, setOpenLevel] = useState(false);
  const [openBoost, setOpenBoost] = useState(false);
  const [openBoostDaily, setOpenBoostDaily] = useState(false);
  const [dataDailyModal, setDataDailyModal] = useState();
  const [dataBoostsModal, setDataBoostsModal] = useState();
  const [dataBoosts, setDataBoosts] = useState();
  const [upd, setUpd] = useState(false);

  const onOpenModalLevel = useCallback(() => {
    setOpenLevel(true);
  }, []);

  const onOpenBoostDaily = useCallback(data => {
    setOpenBoostDaily(true);
    setDataDailyModal(data);
  }, []);

  const onOpenModalBoost = useCallback(data => {
    setOpenBoost(true);
    setDataBoostsModal(data);
  }, []);

  useEffect(() => {
    BoostsHttp.getBoosts(
      id,
      WebApp?.initDataUnsafe?.query_id,
      JSON.stringify(WebApp?.initDataUnsafe?.user),
      WebApp?.initDataUnsafe?.auth_date,
      WebApp?.initDataUnsafe?.hash,
    )
      .then(e => setDataBoosts(e?.data))
      .catch(error => console.log(error, 'error'));

    dispatch(userDataSlice(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, upd]);

  const showBuyData = dataBoosts?.buy?.map(item => {
    // Find the corresponding age object in the ages array
    let ageObj = data.find(ageItem => ageItem.title === item.title);
    // Merge the age property into the item if a match is found
    return ageObj ? { ...item, icon: ageObj.icon, bigIcon: ageObj.bigIcon } : item;
  });

  return (
    <div className="h-[87%]">
      <BackButton />
      <div className="flex flex-col items-center justify-center pt-0 gap-y-2">
        <p className="text-2xl font-semibold font-switzer text-white ">Your share balance</p>
        <p className="text-sm font-normal font-switzer text-[#979797]">Click the boosters below to earn more coins</p>
        <div className="flex flex-col gap-x-2 items-center justify-center">
          <img src={CoinIcon} alt="CoinIcon" className="w-[43px] h-[43px]" />
          <p className="font-switzer text-[#FFEB3B] text-3xl font-semibold">{formatNumber(user?.points_count)}</p>
          <div className="flex gap-x-2 mt-2 items-center" onClick={onOpenModalLevel}>
            <RoundedIconLevels level_id={league?.league_id} />
            <p className="font-switzer text-base font-normal text-white">{league?.title}</p>
            <button className="h-4 w-4">
              <img src={ArrowRight} alt="ArrowRight" />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 px-4">
        <p className="font-base font-medium font-switzer text-white">Your daily boosters</p>
        <div className="flex gap-x-6 mt-2">
          {dataBoosts?.daily?.map(({ title, used_count, description, type, id }) => (
            <div
              key={id}
              className="w-full h-[66px] rounded-[11px] px-4 py-2.5 bg-[#1B1B1B] flex"
              onClick={() =>
                onOpenBoostDaily({ title: title, description: description, type: type, task_id: id, used_count })
              }>
              <div className="flex gap-x-2 items-center">
                {type === 'multiplier' ? (
                  <img src={FireIcon} alt="FireIcon" />
                ) : (
                  <img src={Lightning} alt="Lightning" />
                )}
                <div className="flex flex-col gap-y-1">
                  <p className="font-base font-light text-white">{formatString(title)}</p>
                  <p className="font-base font-norma text-white">{used_count}/3</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="px-4 flex flex-col gap-y-4 max-h-[35%] overflow-y-auto mt-4">
        {showBuyData?.map(({ title, id, level, icon, description, bigIcon, is_done }) => (
          <div
            className="flex items-center justify-between rounded-[11px] bg-[#1B1B1B] px-5 py-2"
            key={id}
            onClick={() =>
              onOpenModalBoost({
                icon: icon,
                title: title,
                description: description,
                level: level,
                task_id: id,
                bigIcon: bigIcon,
                is_done: is_done ?? false,
                points: user?.points_count,
              })
            }>
            <div className="flex items-center gap-x-4 w-full">
              <img src={icon} alt="paperIcon" className="w-[37px] h-[40px]" />
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col items-start">
                  <p className="text-white text-base font-normal font-switzer">{formatString(title)}</p>
                  <div className="flex items-center gap-x-2">
                    <img src={CoinIcon} alt="CoinIcon" className="w-5 h-5" />
                    <p className="text-[#FFEB3B] text-base font-medium font-switzer">{formatNumber(level?.price)}</p>
                  </div>
                </div>
                <img src={ArrowRight} alt="ArrowRight" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <ModalLevels open={openLevel} setOpen={setOpenLevel} id={id} user_points={user?.points_count} />
      <ModalBoosts open={openBoost} setOpen={setOpenBoost} data={dataBoostsModal} id={id} setUpd={setUpd} />
      <ModalBoostsDaily
        open={openBoostDaily}
        setOpen={setOpenBoostDaily}
        data={dataDailyModal}
        id={id}
        setUpd={setUpd}
      />
    </div>
  );
}

import CoinIcon from '../../assets/images/CoinIcon.png';
import Lightning from '../../assets/images/lightning.png';
import ArrowRight from '../../assets/images/ArrowRight.svg';

import { useCallback, useEffect, useMemo, useState } from 'react';
import ModalLevels from '../../components/Modal/ModalLevels';
import { useDispatch, useSelector } from 'react-redux';
import WebApp from '@twa-dev/sdk';
import { userDataSlice } from '../../redux/user/user';
import useWebSocket from 'react-use-websocket';
import RoundedIconLevels from '../../components/RoundedIconLevels/RoundedIconLevels';
import { formatNumber, parseWalletLinks } from '../../utils';
import LoadingPage from '../LoadingPage/LoadingPage';

import video1 from '../../assets/animations/cropped-level1.webm';
import video2 from '../../assets/animations/cropped-level2.webm';
import video3 from '../../assets/animations/cropped-level3.webm';
import video4 from '../../assets/animations/cropped-level4.webm';
import video5 from '../../assets/animations/cropped-level5.webm';
import video6 from '../../assets/animations/cropped-level6.webm';
import video7 from '../../assets/animations/cropped-level7.webm';
import video8 from '../../assets/animations/cropped-level8.webm';
import video9 from '../../assets/animations/cropped-level9.webm';
import video10 from '../../assets/animations/cropped-level10.webm';

export default function Tap() {
  const dispatch = useDispatch();

  function TapLevelSuricate(level_id) {
    const levels = [
      {
        id: 1,
        url: "url('/Level1.png')",
        video: video1,
      },
      {
        id: 2,
        url: "url('/Level2.png')",
        video: video2,
      },
      {
        id: 3,
        url: "url('/Level3.png')",
        video: video3,
      },
      {
        id: 4,
        url: "url('/Level4.png')",
        video: video4,
      },
      {
        id: 5,
        url: "url('/Level5.png')",
        video: video5,
      },
      {
        id: 6,
        url: "url('/Level6.png')",
        video: video6,
      },
      {
        id: 7,
        url: "url('/Level7.png')",
        video: video7,
      },
      {
        id: 8,
        url: "url('/Level8.png')",
        video: video8,
      },
      {
        id: 9,
        url: "url('/Level9.png')",
        video: video9,
      },
      {
        id: 10,
        url: "url('/Level10.png')",
        video: video10,
      },
    ];

    const Leagueicon = levels.find(item => item?.id === level_id);

    return Leagueicon;
  }

  const id = WebApp?.initDataUnsafe?.user?.id;

  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [clicks, setClicks] = useState([]);

  const onOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const user = useSelector(state => state?.user?.userData?.user);
  const league = useSelector(state => state?.user?.userData?.league);

  const link = '/api/v1/tap/';

  const socketUrl = useMemo(() => `${link}${id}`, [id]);

  const { sendMessage } = useWebSocket(socketUrl, {
    share: true,
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: () => console.log('Connected to WebSocket'),
    onClose: () => console.log('Disconnected from WebSocket'),
    onError: e => console.error('WebSocket Error', e),
    onMessage: message => {
      const current = JSON.parse(message.data);
      setCurrentData(current);
    },
    shouldReconnect: closeEvent => true, // Reconnect on close
  });

  // Handle send message button click
  const handleClickSendMessage = useCallback(
    e => {
      if (currentData?.current_energy > 0) {
        const card = e.currentTarget;
        setClicks([...clicks, { id: Date.now(), x: e.pageX, y: e.pageY }]);
        setTimeout(() => {
          card.style.transform = '';
        }, 100);
        sendMessage('tap');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData],
  );

  const handleTouchStart = useCallback(
    e => {
      if (currentData?.current_energy > 0) {
        const touches = e.touches ? Array.from(e.touches) : []; // Convert touchlist to an array
        const newClicks = touches?.map(touch => ({
          id: Date.now() + touch?.identifier,
          x: touch?.pageX,
          y: touch?.pageY,
        }));
        setClicks(prevClicks => [...prevClicks, ...newClicks]);

        setTimeout(() => {
          newClicks?.forEach(click => {
            const element = document.getElementById(`click-${click.id}`);
            if (element) {
              element.style.transform = '';
            }
          });
        }, 100);

        sendMessage('tap');
      }
    },
    [currentData, sendMessage],
  );

  // const handleAnimationEnd = useCallback(id => {
  //   setClicks(prevClicks => prevClicks.filter(click => click.id !== id));
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      sendMessage('update');
    }, 800);
    return () => clearInterval(interval);
  }, [sendMessage]);

  useEffect(() => {
    dispatch(userDataSlice(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);



  return (
    <div className={`${WebApp?.platform === 'tdesktop' && currentData?.['is_multiply_on'] ? 'h-[87%]' : 'h-full'}`}>
      {currentData?.['current_points_count'] || currentData?.['current_points_count'] == '0' ? (
        <div className="h-full">
          <div
            onClick={WebApp?.platform === 'tdesktop' ? handleClickSendMessage : handleTouchStart}
            onTouchStart={handleTouchStart}
            className="w-full relative flex  justify-center h-[93%]"
            style={{
              backgroundImage: !currentData?.['is_multiply_on']
                ? TapLevelSuricate(league?.league_id || 1)?.url
                : 'unset',
              backgroundSize: !currentData?.['is_multiply_on'] ? 'contain' : 'unset',
              backgroundPositionX: !currentData?.['is_multiply_on'] ? 'center' : 'unset',
              backgroundRepeat: !currentData?.['is_multiply_on'] ? 'no-repeat' : 'unset',
            }}>
            {currentData?.['is_multiply_on'] && (
              // <img
              //   src={TapLevelSuricate(league?.league_id)?.gif}
              //   alt="gif"
              //   style={{
              //     height: 'initial',
              //     width: '100%',
              //     display: 'block',
              //   }}
              // />
              <video autoPlay loop muted playsInline>
                <source src={TapLevelSuricate(league?.league_id)?.video} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            )}
            {clicks.map((click, index) => (
              <div
                key={click.id}
                className="absolute text-4xl opacity-0 font-bold text-white pointer-events-none "
                style={{
                  top: `${click.y - 42}px`,
                  left: `${click.x - 28}px`,
                  animation: `float 1.5s ease-out`,
                }}
                // onAnimationEnd={() => handleAnimationEnd(click.id)}
              >
                <div className="flex flex-col items-center">
                  <img src={CoinIcon} alt="CoinIcon" className="w-6 h-6" />
                  <p>+{formatNumber(currentData?.['is_multiply_on'] ? user?.tap_cost * 5 : user?.tap_cost)}</p>
                </div>
              </div>
            ))}
            <div className="top-[21px] absolute">
              <div className="flex gap-x-2 items-center">
                <img src={CoinIcon} alt="CoinIcon" />
                <p className="text-3xl text-[#FFEB3B] font-semibold font-switzer">
                  {formatNumber(currentData?.['current_points_count'])}
                </p>
              </div>
            </div>
            <div className="absolute w-full bottom-[5px] px-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <img src={Lightning} alt="Lightning" />
                  <p className="font-semibold text-2xl text-white font-switzer">
                    {formatNumber(currentData?.['current_energy'])}{' '}
                    <span className="text-base text-white font-medium font-switzer">
                      /{formatNumber(user?.energy_limit)}
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-x-2 coursor-pointer" onClick={onOpenModal}>
                  <RoundedIconLevels level_id={league?.league_id} />
                  <p className="font-switzer text-base text-white font-normal" style={{ cursor: 'pointer' }}>
                    {league?.title}
                  </p>
                  <button className="h-4 w-4 coursor-pointer">
                    <img src={ArrowRight} alt="ArrowRight" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-2">
            <div className="rounded-[44px] bg-gradient-to-b from-[#FFB000] to-[#FF4000] w-full h-[20px]"></div>
          </div>
          <ModalLevels open={open} setOpen={setOpen} id={id} user_points={currentData?.['current_points_count']} />
        </div>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

import Loading from '../../assets/images/Loading.png';

export default function LoadingPage() {
  return (
    <div className="bg-[#0F0F0F] h-screen flex flex-col justify-center items-center">
      <div className="h-full w-[75%] mx-auto flex flex-col justify-center items-center">
        <img src={Loading} alt="Loading" />
        <p className='text-white text-2xl mt-6 font-medium'>Loading ...</p>
      </div>
    </div>
  );
}

// import { useSelector } from 'react-redux';
import RootContainer from './pages/RootContainer/RootContainer';
import './App.css';
import { RemoveScroll } from 'react-remove-scroll';
import WebApp from '@twa-dev/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { referralsDataSlice } from './redux/referrals/referrals';
import QRCode from 'qrcode.react';

function App() {
  const valueToEncode = `${process.env.REACT_APP_QR_LINK}`;
  const dispatch = useDispatch();
  const id = WebApp?.initDataUnsafe?.user?.id;

  const referralList = useSelector(state => state?.referrals?.referralsData);

  useEffect(() => {
    !referralList && dispatch(referralsDataSlice(id));
  }, [referralList]);



  return (
    <RemoveScroll>
      {id ? (
        <div className="h-screen">
          <RootContainer />
        </div>
      ) : (
        <div className="bg-[black] h-screen flex flex-col justify-center items-center">
          <div className="h-full w-[75%] mx-auto flex flex-col justify-center items-center">
            <p className="text-white text-4xl mb-6">Play on your mobile</p>
            <div
              style={{
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
                border: '2px solid white',
              }}>
              <QRCode value={valueToEncode} size={380} fgColor="black" bgColor="white" />
            </div>
          </div>
        </div>
      )}
    </RemoveScroll>
  );
}

export default App;

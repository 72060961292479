import { $authHost } from "..";


export default class UserHttp {

  static getUser(id,query_id,user,auth_date,hash) {
    return $authHost.get(`/users/${id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static createUser(data,query_id,user,auth_date,hash) {
    return $authHost.post(`/users?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`,data);
  };
}
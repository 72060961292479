import WebApp from '@twa-dev/sdk';
import CoinIcon from '../../../../assets/images/CoinIcon.png';
import Present from '../../../../assets/images/Present.svg';
import TaskHttp from '../../../../http/TaskHttp/TaskHttp';
import { formatNumber } from '../../../../utils';
export default function ShowReferrals({ data, telegram_id, setUpd }) {
  const onClaimReferral = (id, task_id) => {
    TaskHttp.claimReferral(id, task_id,WebApp?.initDataUnsafe?.query_id, JSON.stringify(WebApp?.initDataUnsafe?.user), WebApp?.initDataUnsafe?.auth_date, WebApp?.initDataUnsafe?.hash).then(e => setUpd(prev => !prev));
  };

  return (
    <div className="px-4 flex flex-col gap-y-2 mt-2 max-h-[46%] overflow-y-auto">
      {data?.map(({ title, id, bonus_points, status }) => (
        <div className="flex flex-col  bg-[#1B1B1B] rounded-[11px] gap-y-2 px-5 py-2">
          <div className="flex items-center justify-between " key={id}>
            <div className="flex items-center gap-x-4 w-full">
              <img src={Present} alt="icon" className="w-[48px] h-[48px]" />
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col items-start">
                  <p className="text-white text-base font-normal font-switzer">{title}</p>
                  <div className="flex items-center gap-x-2">
                    <img src={CoinIcon} alt="CoinIcon" className="w-5 h-5" />
                    <p className="text-[#FFEB3B] text-base font-medium font-switzer">{formatNumber(bonus_points)}</p>
                  </div>
                </div>
                <button
                  className={`${
                    status === 'unclaimed'
                      ? `w-[64px] bg-gradient-to-b from-[#FFB000] to-[#FF4000] h-[28px] rounded-[22px] flex items-center justify-center text-sm font-normal text-white`
                      : `w-[64px] h-[28px] rounded-[22px] flex items-center justify-center text-sm font-normal text-white bg-[#979797] cursor-not-allowed	`
                  }
                   $`}
                  disabled={status !== 'unclaimed'}
                  onClick={() => onClaimReferral(telegram_id, id)}>
                  {status === 'claimed' ? 'Claimed' : 'Claim'}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

import CoinIcon from '../../../assets/images/CoinIcon.png';
import { ButtonName, formatNumber, parseWalletLinks, TaskMissionIcon } from '../../../utils';
import TaskHttp from '../../../http/TaskHttp/TaskHttp';
import Done from '../../../assets/images/Done.svg';
import WebApp from '@twa-dev/sdk';
import { useState } from 'react';

export default function TaskComponent({ setUpd, id, bonus_points, icon, title, status, tg_id, link }) {
  const [timeLeft, setTimeLeft] = useState(null);

  const startTimer = () => {
    setTimeLeft(10);
    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime === 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const navigate = (link, icon) => {
    if (icon === 'app') {
      const parsedLinks = parseWalletLinks(link);
      const appLink = parsedLinks.find(item => item?.title === WebApp?.platform);
      if (link?.startsWith('https://t.me/')) {
        WebApp.openTelegramLink(appLink?.link);
      } else {
        WebApp.openLink(appLink?.link);
      }
    } else if (link?.startsWith('https://t.me/')) {
      WebApp.openTelegramLink(link);
    } else {
      WebApp.openLink(link);
    }
  };

  const onClickButton = (status, id, task_id, link,icon) => {
    status === 'created'
      ? TaskHttp.startTask(
          id,
          task_id,
          WebApp?.initDataUnsafe?.query_id,
          JSON.stringify(WebApp?.initDataUnsafe?.user),
          WebApp?.initDataUnsafe?.auth_date,
          WebApp?.initDataUnsafe?.hash,
        )
          .then(e => {
            navigate(link,icon);
            startTimer();
          })
          .then(() => setUpd(prev => !prev))
      : status === 'unclaimed' &&
        TaskHttp.claimTask(
          id,
          task_id,
          WebApp?.initDataUnsafe?.query_id,
          JSON.stringify(WebApp?.initDataUnsafe?.user),
          WebApp?.initDataUnsafe?.auth_date,
          WebApp?.initDataUnsafe?.hash,
        ).then(e => {
          setUpd(prev => !prev);
        });
  };

  return (
    <div
      className="flex flex-col  bg-[#1B1B1B] rounded-[11px] gap-y-2 px-5 py-2"
      key={id + bonus_points}
      onClick={() => {
        if (status === 'claimed') {
          navigate(link,icon);
        }
      }}>
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-x-4 w-full">
          <img src={TaskMissionIcon(icon)} alt="icon" className="w-[48px] h-[48px]" />
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col items-start">
              <p className="text-white text-base font-normal font-switzer">{title}</p>
              <div className="flex items-center gap-x-2">
                <img src={CoinIcon} alt="CoinIcon" className="w-5 h-5" />
                <p className="text-[#FFEB3B] text-base font-medium font-switzer">{formatNumber(bonus_points)}</p>
              </div>
            </div>
            {status === 'claimed' ? (
              <img src={Done} alt="Done" className="w-6 h-6" />
            ) : timeLeft ? (
              <button
                className={`${`w-[64px] bg-[#3D3D40] text-[#979797] h-[28px] rounded-[22px] flex items-center justify-center text-sm font-normal text-white`}
              `}>
                {timeLeft !== 0 && timeLeft}
              </button>
            ) : (
              <button
                className={`${`w-[64px] bg-gradient-to-b from-[#FFB000] to-[#FF4000] h-[28px] rounded-[22px] flex items-center justify-center text-sm font-normal text-white`}
              `}
                onClick={() => onClickButton(status, tg_id, id, link,icon)}>
                {ButtonName(status)?.name}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { $authHost } from "..";


export default class TaskHttp {

  static getTasksList(id,query_id,user,auth_date,hash) {
    return $authHost.get(`/missions/${id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static getLeaguesList(id,query_id,user,auth_date,hash) {
    return $authHost.get(`/missions/${id}/leagues?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static getReferralsList(id,query_id,user,auth_date,hash) {
    return $authHost.get(`/missions/${id}/referrals?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static claimReferral(id,task_id,query_id,user,auth_date,hash) {
    return $authHost.patch(`/missions/${id}/referrals/claim/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static claimMission(id,task_id,query_id,user,auth_date,hash) {
    return $authHost.patch(`/missions/${id}/claim/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static claimLeague(id,task_id,query_id,user,auth_date,hash) {
    return $authHost.patch(`/missions/${id}/leagues/claim/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static startTask(id,task_id,query_id,user,auth_date,hash) {
    return $authHost.patch(`/tasks/${id}/start/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
  static claimTask(id,task_id,query_id,user,auth_date,hash) {
    return $authHost.patch(`/tasks/${id}/claim/${task_id}?query_id=${query_id}&user=${user}&auth_date=${auth_date}&hash=${hash}`);
  };
}
import React, { useMemo } from 'react';

import Ant from '../../assets/images/Ant.png';
import AntIcon from '../../assets/images/AntIcon.png';
import Larvae from '../../assets/images/Larvae.png';
import LarvaeIcon from '../../assets/images/LarvaeIcon.png';
import Scorpion from '../../assets/images/Scorpion.png';
import ScorpionIcon from '../../assets/images/ScorpionIcon.png';
import Ladybug from '../../assets/images/Ladybug.png';
import LadybugIcon from '../../assets/images/LadybugIcon.png';
import Butterfly from '../../assets/images/Butterfly.png';
import ButterflyIcon from '../../assets/images/ButterflyIcon.png';
import Termite from '../../assets/images/Termite.png';
import TermiteIcon from '../../assets/images/TermiteIcon.png';
import Spider from '../../assets/images/Spider.png';
import SpiderIcon from '../../assets/images/SpiderIcon.png';
import Dragonfly from '../../assets/images/Dragonfly.png';
import DragonflyIcon from '../../assets/images/DragonflyIcon.png';
import Grasshopper from '../../assets/images/Grasshopper.png';
import GrasshopperIcon from '../../assets/images/GrasshopperIcon.png';
import Bee from '../../assets/images/Bee.png';
import BeeIcon from '../../assets/images/BeeIcon.png';

export default function RoundedIconLevels({ level_id, big }) {
  const levels = useMemo(
    () => [
      {
        id: 1,
        icon: AntIcon,
        bigIcon: Ant,
      },
      {
        id: 2,
        icon: LarvaeIcon,
        bigIcon: Larvae,
      },
      {
        id: 3,
        icon: ScorpionIcon,
        bigIcon: Scorpion,
      },
      {
        id: 4,
        icon: LadybugIcon,
        bigIcon: Ladybug,
      },
      {
        id: 5,
        icon: ButterflyIcon,
        bigIcon: Butterfly,
      },
      {
        id: 6,
        icon: TermiteIcon,
        bigIcon: Termite,
      },
      {
        id: 7,
        icon: SpiderIcon,
        bigIcon: Spider,
      },
      {
        id: 8,
        icon: DragonflyIcon,
        bigIcon: Dragonfly,
      },
      {
        id: 9,
        icon: GrasshopperIcon,
        bigIcon: Grasshopper,
      },
      {
        id: 10,
        icon: BeeIcon,
        bigIcon: Bee,
      },
    ],
    [],
  );

  const Leagueicon = levels.find(item => item?.id === level_id);

  return <img src={big ? Leagueicon?.bigIcon : Leagueicon?.icon} alt="Leagueicon" className="coursor-pointer" />;
}

import CoinIcon from '../../../../assets/images/CoinIcon.png';
import ArrowRight from '../../../../assets/images/ArrowRight.svg';
import paperIcon from '../../../../assets/images/PaperIcon.svg';
import Done from '../../../../assets/images/Done.svg';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import { formatNumber } from '../../../../utils';

export default function ShowSpecial({ data, tg_id }) {
  const navigate = useNavigate();

  const onClickNavigate = (data, desc, title, bonus_points, id,status) => {
    data.description = desc;
    data.title = title;
    data.bonus_points = bonus_points;
    data.tg_id = tg_id;
    data?.length && navigate(`/task/ourSocials/${id}`);
  };

  const height = WebApp.viewportHeight;

  return (
    <div className={`px-4 flex flex-col  ${height > 667 ? 'gap-y-4' : 'gap-y-2'}  max-h-[46%] overflow-y-auto mt-2`}>
      {data?.map(({ title, id, bonus_points, status, tasks, description }) => (
        <div className="flex items-center justify-between rounded-[11px] bg-[#1B1B1B] px-5 py-2" key={id}>
          <div className="flex items-center gap-x-4 w-full">
            <img src={paperIcon} alt="paperIcon" />
            <div
              className="flex  items-center justify-between w-full"
              onClick={() => onClickNavigate(tasks, description, title, bonus_points, id,status)}>
              <div className="flex flex-col items-start">
                <p className="text-white text-base font-normal font-switzer ">{title}</p>
                <div className="flex items-center gap-x-2">
                  <img src={CoinIcon} alt="CoinIcon" className="w-5 h-5" />
                  <p className="text-[#FFEB3B] text-base font-medium font-switzer">{formatNumber(bonus_points)}</p>
                </div>
              </div>
              {status !== 'claimed' ? <img src={ArrowRight} alt="ArrowRight" /> : <img src={Done} alt="Done" />}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

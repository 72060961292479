import { Outlet } from 'react-router-dom';
import Menu from '../Menu/Menu';
import WebApp from '@twa-dev/sdk';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userDataSlice } from '../../redux/user/user';


export default function Layout() {
  const dispatch = useDispatch();

  const id = WebApp?.initDataUnsafe?.user?.id;
  useEffect(() => {
    WebApp.ready();
    WebApp.expand(true);
  }, []);

  useEffect(() => {
    dispatch(userDataSlice(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
      <div className="h-full flex flex-col justify-between">
      <Outlet />
      <Menu />
    </div>
  );
}
